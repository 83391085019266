<template>
  <UiPopup
    :model-value="modelValue"
    title="Meeting outcome"
    :description="
      activity.lead
        ? `The scheduled meeting with the lead ${activity.lead?.name} has ended. Please fill in the meeting outcome; this information will be added to the lead's timeline.`
        : 'The scheduled meeting has ended. Please fill in the meeting outcome; this information will be added to the lead\'s timeline.'
    "
    primary-button-text="Save"
    :secondary-button-text="closable ? 'Close' : ''"
    size="small"
    :hide-close-button="!closable"
    :loading="loading"
    :align-buttons-end="true"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <div class="my-10 flex flex-col gap-4">
      <div class="flex flex-row items-center justify-between rounded-lg bg-primary-05 p-3">
        <h5>{{ activity.lead?.name }}</h5>
        <div class="flex flex-row items-center gap-1">
          <p class="text-caption-2">
            {{ format(new Date(activity.start), 'MMM d, HH:mm') }} - {{ format(new Date(activity.end), 'HH:mm') }}
          </p>
          <UiIcon name="clock" class="text-black-70" />
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <span>The resolution for meeting is:</span>
        <UiInputSelect
          id="resolution"
          v-model="data.resolutionId"
          name="resolution"
          :items="useSerializeLibraryItems(librariesStore.getMeetingOutcomeResolutions)"
          placeholder="Meeting resolution"
          :error="useGetFieldErrors(v$, ['resolutionId'])"
        />
      </div>
      <div class="flex flex-col gap-2">
        <span>Write a note and it'll be saved in Lead timeline.</span>
        <UiInputTextarea
          id="resolution"
          v-model="data.note"
          name="resolution"
          placeholder="Optional note to Timeline"
          :error="useGetFieldErrors(v$, ['note'])"
        />
      </div>
    </div>
    <UiButtonBase id="other-outcomes" type="text" class="absolute bottom-10" @click="navigateToOtherOutcomes"
      >Other pending outcomes <UiIcon name="arrow-small-right"></UiIcon
    ></UiButtonBase>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, maxLength } from '@vuelidate/validators'
import { format } from 'date-fns'
import { SIDE_PANELS } from '@/components/dynamic/maps'
import { useUiStore } from '@/store/ui'
import { useLibrariesStore } from '@/store/libraries'
import type { Activity } from '@/types'
import { MEETING_OUTCOME_RESOLUTION_CODES } from '@/constants'

const uiStore = useUiStore()
const librariesStore = useLibrariesStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  activity: Activity
  closable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  closable: true,
})

onNuxtReady(async () => {
  await useMeetingOutcomeResolutions()
})

const loading = ref(false)

const data = ref({
  resolutionId: undefined,
  note: '',
})

const rules = computed(() => ({
  resolutionId: { required: helpers.withMessage('The resolution is required', required) },
  note: { maxLength: helpers.withMessage("Note shouldn't be longer than 500 characters ", maxLength(500)) },
}))

const v$ = useVuelidate(rules, data)

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return
  try {
    if (
      librariesStore.getMeetingOutcomeResolutions.find((r) => r.id === data.value.resolutionId)?.code ===
      MEETING_OUTCOME_RESOLUTION_CODES.RESCHEDULED
    ) {
      uiStore.showSidePanel(SIDE_PANELS.ACTIVITY_CREATE_EDIT, { activityToEdit: props.activity })
    } else {
      loading.value = true
      await useSetMeetingOutcome(props.activity.id!, data.value.resolutionId!, data.value.note)
      uiStore.showSnackBanner()
      emits('input', data.value.resolutionId)
    }
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}

const navigateToOtherOutcomes = async () => {
  const isValid = await v$.value.$validate()
  if (isValid) submit()
  navigateTo('/activities?meeting_outcome_status_ids=1', { open: { target: '_blank' } })
}
</script>

<style scoped></style>
